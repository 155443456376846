<template>
	<div class="card card-custom">
		<div class="card-body p-0">
			<div class="py-8 px-8 py-lg-20 px-lg-10">
				<div class="row">
					<div class="col-md-6">
						<h4 class="mb-10 font-weight-bold text-dark">
							Enter your Account Details
						</h4>

						<div class="form-group">
							<label>Plan Types</label>
							<b-form-select
								class="form-control form-control-solid form-control-lg"
								name="country"
								v-model="account_type_id"
								:disabled="true"
								:options="accountTypes"
							>
							</b-form-select>
							<span class="form-text text-muted"
								>Please select your plan.</span
							>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>First Name</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										name="fname"
										placeholder="First Name"
										v-model="first_name"
									/>
									<span class="form-text text-muted"
										>Please enter your first name.</span
									>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Last Name</label>
									<input
										type="text"
										class="form-control form-control-solid form-control-lg"
										name="lname"
										placeholder="Last Name"
										v-model="last_name"
									/>
									<span class="form-text text-muted"
										>Please enter your last name.</span
									>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>Phone</label>
							<input
								type="tel"
								class="form-control form-control-solid form-control-lg"
								name="phone"
								placeholder="phone"
								v-model="cell_phone"
							/>
							<span class="form-text text-muted"
								>Please enter your phone number.</span
							>
						</div>

						<div class="form-group">
							<label>Date of birth</label>
							<b-form-datepicker
								v-model="date_of_birth"
								class="form-control form-control-solid"
							></b-form-datepicker>
							<span class="form-text text-muted"
								>Please enter your birthdate.</span
							>
						</div>
					</div>

					<div class="col-md-6">
						<input
							ref="crop_input"
							type="file"
							name="image"
							accept="image/*"
							@change="setImage"
						/>
						<div class="row">
							<div class="col-md-12">
								<p>Profile Image</p>
								<vue-cropper
									ref="cropper"
									:src="imgSrc"
									class="cropper-min-height"
									:aspectRatio="1 / 1"
									:initialAspectRatio="1 / 1"
									:viewMode="3"
								/>
							</div>
							<div class="col-md-12">
								<div class="actions mt-3 text-center">
									<b-button
										class="mr-3 mb-3"
										@click="rotate(90)"
									>
										<i
											class="fa fa-share"
											aria-hidden="true"
										></i>
									</b-button>
									<b-button
										class="mr-3 mb-3"
										@click="rotate(-90)"
									>
										<i
											class="fa fa-reply"
											aria-hidden="true"
										></i>
									</b-button>
									<b-button
										@click="cropImage"
										class="mr-3 mb-3"
									>
										<i
											class="fa fa-crop"
											aria-hidden="true"
										></i>
									</b-button>
									<b-button @click="reset" class="mr-3 mb-3">
										<i
											class="fa fa-arrows-alt"
											aria-hidden="true"
										></i>
									</b-button>
									<b-button
										@click="showFileChooser"
										class="mr-3 mb-3"
									>
										<i
											class="fa fa-upload"
											aria-hidden="true"
										></i>
									</b-button>
									<b-button
										@click="download"
										class="mr-3 mb-3"
									>
										<i
											class="fa fa-download"
											aria-hidden="true"
										></i>
									</b-button>
								</div>
							</div>
							<div class="col-md-12">
								<p>Cropped Image</p>
								<div class="cropped-image secondary">
									<img
										v-if="cropImg"
										:src="cropImg"
										alt="Cropped Image"
									/>
									<div v-else class="crop-placeholder" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<h4 class="mb-10 font-weight-bold text-dark">
					Setup Your Current Location
				</h4>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label>Address</label>
							<input
								type="text"
								class="form-control form-control-solid form-control-lg"
								name="address1"
								placeholder="Address"
								v-model="street_address"
							/>
							<span class="form-text text-muted"
								>Please enter your Address.</span
							>
						</div>

						<div class="form-group">
							<label>zip code</label>
							<input
								type="text"
								class="form-control form-control-solid form-control-lg"
								name="zip code"
								placeholder="zip code"
								v-model="zip_code"
							/>
							<span class="form-text text-muted">
								Please enter your zip code.
							</span>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label>Country</label>
							<b-form-select
								class="form-control form-control-solid form-control-lg"
								name="country"
								v-model="country_id"
								:options="countries"
							>
							</b-form-select>
							<span class="form-text text-muted"
								>Please select your country.</span
							>
						</div>
						<div class="form-group">
							<label>City</label>
							<input
								type="text"
								class="form-control form-control-solid form-control-lg"
								name="city"
								placeholder="City"
								v-model="city"
							/>
							<span class="form-text text-muted">
								Please enter your City.
							</span>
						</div>
					</div>
				</div>
				<button
					v-on:click="submit"
					class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
				>
					Submit
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
	name: "Info",
	data() {
		return {
			first_name: "",
			last_name: "",
			account_type_id: 0,
			country_id: 0,
			cell_phone: "",
			date_of_birth: "1980-01-01",
			street_address: "",
			city: "",
			zip_code: "",
			countries: [],
			accountTypes: [],
			imgSrc:
				"media/users/300_" +
				(Math.floor(Math.random() * 25) + 1) +
				".jpg",
			cropImg: "",
			fileExtension: "png"
		};
	},
	components: {
		VueCropper
	},
	created() {
		this.getCountries();
		this.getAccountTypes();
	},
	computed: {
		selectedCountry() {
			try {
				return this.countries.filter(
					obj => obj.value == this.country_id
				)[0].text;
			} catch (error) {
				return "";
			}
		},
		selectedAccountType() {
			try {
				return this.accountTypes.filter(
					obj => obj.value == this.account_type_id
				)[0].text;
			} catch (error) {
				return "";
			}
		}
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: "Client", route: { name: "dashboard" } },
			{ title: "Update Profile" }
		]);
	},
	methods: {
		submit: function(e) {
			e.preventDefault();

			var formData = new FormData();
			formData.append("first_name", this.first_name);
			formData.append("last_name", this.last_name);
			formData.append("account_type_id", this.account_type_id);
			formData.append("country_id", this.country_id);
			formData.append("cell_phone", this.cell_phone);
			formData.append("date_of_birth", this.date_of_birth);
			formData.append("street_address", this.street_address);
			formData.append("city", this.city);
			formData.append("zip_code", this.zip_code);

			fetch(this.cropImg)
				.then(res => res.blob())
				.then(blob => {
					const file = new File([blob], "profile_image.png");
					if (this.cropImg != "") {
						formData.append("image", file);
					}
					this.$store
						.dispatch(UPDATE_USER, formData)
						.then(data => {
							if (data.code == 200) {
								Swal.fire({
									title: "",
									text:
										"Your profile has been successfully Updated!",
									icon: "success",
									confirmButtonClass: "btn btn-secondary"
								}).then(() => {
									this.$router.push({ name: "dashboard" });
								});
							} else {
								Swal.fire({
									title: data.message,
									text: Object.values(data.errors)
										.flat()
										.map(function(item) {
											return "• " + item;
										}),
									icon: "error",
									confirmButtonClass: "btn btn-secondary"
								});
							}
						})
						.catch(() => {})
						.finally(() => {
							// submitButton.disabled = false;
							// submitButton.classList.remove(
							// 	"disabled",
							// 	"spinner",
							// 	"spinner-light",
							// 	"spinner-right"
							// );
						});
				});
		},
		getCountries() {
			ApiService.get("public/country/").then(({ data }) => {
				this.countries = data.data;
				try {
					this.country_id = this.countries[0].value;
				} finally {
					// always runs
				}
			});
		},
		getAccountTypes() {
			ApiService.get("public/account-type/").then(({ data }) => {
				this.accountTypes = data.data;
				try {
					this.account_type_id = this.accountTypes[0].value;
				} finally {
					// always runs
				}
			});
		},
		cropImage() {
			// get image data for post processing, e.g. upload or setting image src
			this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
		},
		reset() {
			this.$refs.cropper.reset();
		},
		rotate(deg) {
			this.$refs.cropper.rotate(deg);
		},
		setImage(e) {
			const file = e.target.files[0];
			this.fileExtension = file.name.split(".").pop();
			if (file.type.indexOf("image/") === -1) {
				alert("Please select an image file");
				return;
			}

			if (typeof FileReader === "function") {
				const reader = new FileReader();
				reader.onload = event => {
					this.imgSrc = event.target.result;
					// rebuild cropperjs with the updated source
					this.$refs.cropper.replace(event.target.result);
				};
				reader.readAsDataURL(file);
			} else {
				alert("Sorry, FileReader API not supported");
			}
		},
		showFileChooser() {
			this.$refs.crop_input.click();
		},
		download() {
			if (this.cropImg) {
				var extention = this.fileExtension;
				var href = this.cropImg;
				var link = document.createElement("a");
				link.addEventListener(
					"click",
					function() {
						link.href = href;
						link.download = "cropper-img." + extention;
					},
					false
				);
				link.click();
			} else {
				alert("Please, crop image before download it");
			}
		}
	}
};
</script>

<style scoped>
input[type="file"] {
	display: none;
}

.content {
	display: flex;
	justify-content: space-between;
}

.cropped-image img {
	max-width: 100%;
}

.cropper-bg {
	background-repeat: repeat;
}

.cropper-min-height {
	height: 400px;
	width: 100%;
}
</style>
